import { Input as AInput, Collapse, Switch, Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import Input from 'components/TrackingComponents/Input';
import TrackingInput from 'components/TrackingComponents/TrackingInput';
import styled from 'styled-components';
import translations from 'translations';
import utilsMessages from 'utils/messages';
import Select, { SelectItem } from 'components/Select/Select';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import React, { FC, useState } from 'react';
interface VATInvoiceFormSectionProps {
  form: any;
  enabledVATInvoiceKey: boolean;
  onEnableGetVATInvoiceEvent: SwitchChangeEventHandler;
  resetDistrictAndWard: () => void;
  getDistrictsByCity: (city: string) => SelectItem[];
  resetWard: () => void;
  cities: SelectItem[];
  getWardsByCityAndDistrict: (city: string, district: string) => SelectItem[];
}

const { Panel } = Collapse;

const CustomPanel = styled(Panel)<any>`
  border-bottom: 0 !important;
  .ant-collapse-header {
    padding-left: 0 !important;
    padding-right: 0 !important;
    color: #595959 !important;
  }
  .ant-collapse-content-box {
    padding: 0 !important;
  }
  .ant-collapse-item {
    border-bottom: 0 !important;
  }

  .ant-collapse-arrow {
    right: 0 !important;
  }

  .ant-collapse-header {
    cursor: default !important;
  }
`;

const AddressInfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
  > div {
    flex: 1;
    max-width: 140px;
  }
`;

const VATInvoiceFormSection: FC<VATInvoiceFormSectionProps> = (props) => {
  const {
    enabledVATInvoiceKey,
    resetDistrictAndWard,
    onEnableGetVATInvoiceEvent,
    getDistrictsByCity,
    resetWard,
    getWardsByCityAndDistrict,
    cities,
    form,
  } = props;
  // const [form as formTest] = Form.useForm();
  const { getFieldValue } = form;
  const [districtList, setDistrictList] = useState<any>([]);
  const [wardList, setWardList] = useState<any>([]);

  const onChangeCity = (value) => {
    const districtData = getDistrictsByCity(value);
    setDistrictList(districtData);
    resetDistrictAndWard();
  };
  const onChangeDistrict = (value) => {
    const wardData = getWardsByCityAndDistrict(getFieldValue('redInvoiceInfo')?.location?.city, value);
    setWardList(wardData);
    resetWard();
  };
  return (
    <Collapse
      bordered={false}
      expandIcon={() => <Switch checked={enabledVATInvoiceKey} onChange={onEnableGetVATInvoiceEvent} />}
      expandIconPosition="end"
      activeKey={[enabledVATInvoiceKey ? 'VATInvoicePanel' : '']}
      style={{ backgroundColor: 'unset' }}
    >
      <CustomPanel
        header={<FormattedMessage {...utilsMessages.getVATInvoiceOrNot} />}
        collapsible="disabled"
        key="VATInvoicePanel"
      >
        {enabledVATInvoiceKey ? (
          <>
            <FormattedMessage {...utilsMessages.companyName}>
              {(companyName) => (
                <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: companyName }}>
                  {(fieldRequired) => (
                    <Form.Item
                      name={['redInvoiceInfo', 'companyName']}
                      colon={false}
                      required={false}
                      label={companyName}
                      hasFeedback
                      rules={[{ required: true, message: fieldRequired }]}
                    >
                      <Input
                        InputComponent={AInput}
                        placeholder={companyName}
                        trackingCategory="Contact Us Form | Input"
                        trackingAction="Company Name"
                      />
                    </Form.Item>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
            <FormattedMessage {...utilsMessages.taxCode}>
              {(taxCode) => (
                <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: taxCode }}>
                  {(fieldRequired) => (
                    <Form.Item
                      name={['redInvoiceInfo', 'taxCode']}
                      colon={false}
                      required={false}
                      label={taxCode}
                      rules={[{ required: true, message: fieldRequired }]}
                      hasFeedback
                    >
                      <Input
                        InputComponent={AInput}
                        placeholder={taxCode}
                        trackingCategory="Contact Us Form | Input"
                        trackingAction="Tax Code"
                      />
                    </Form.Item>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
            <FormattedMessage {...utilsMessages.emailToReceiveEInvoice}>
              {(emailToReceiveEInvoice) => (
                <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: emailToReceiveEInvoice }}>
                  {(fieldRequired) => (
                    <Form.Item
                      name={['redInvoiceInfo', 'emailToReceiveEInvoice']}
                      colon={false}
                      required={false}
                      label={emailToReceiveEInvoice}
                      hasFeedback
                      rules={[
                        {
                          type: 'email',
                          message: translations(utilsMessages.fieldEmail),
                        },
                        { required: true, message: fieldRequired },
                      ]}
                      normalize={(value: string) => value && value.replace(/\s/g, '')}
                    >
                      <Input
                        InputComponent={AInput}
                        placeholder={emailToReceiveEInvoice}
                        trackingCategory="Contact Us Form | Input"
                        trackingAction="Enter Email to receive e-invoice"
                      />
                    </Form.Item>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
            <FormattedMessage {...utilsMessages.address}>
              {(address) => (
                <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: address }}>
                  {(fieldRequired) => (
                    <Form.Item
                      name={['redInvoiceInfo', 'location', 'address']}
                      colon={false}
                      required={false}
                      label={address}
                      hasFeedback
                      rules={[{ required: true, message: fieldRequired }]}
                    >
                      <Input
                        InputComponent={AInput}
                        placeholder={address}
                        trackingCategory="Contact Us Form | Input"
                        trackingAction="Enter Address"
                      />
                    </Form.Item>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
            <AddressInfoContainer>
              <FormattedMessage {...utilsMessages.city}>
                {(city) => (
                  <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: city }}>
                    {(fieldRequired) => (
                      <Form.Item
                        name={['redInvoiceInfo', 'location', 'city']}
                        colon={false}
                        required={false}
                        label={city}
                        rules={[{ required: true, message: fieldRequired }]}
                      >
                        <TrackingInput
                          placeholder={city}
                          options={{
                            action: 'Enter City',
                            category: 'Contact Us Form | Input',
                          }}
                          onChange={onChangeCity}
                        >
                          <Select items={cities} />
                        </TrackingInput>
                      </Form.Item>
                    )}
                  </FormattedMessage>
                )}
              </FormattedMessage>
              <FormattedMessage {...utilsMessages.district}>
                {(district) => (
                  <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: district }}>
                    {(fieldRequired) => (
                      <Form.Item
                        name={['redInvoiceInfo', 'location', 'district']}
                        colon={false}
                        required={false}
                        label={district}
                        rules={[{ required: true, message: fieldRequired }]}
                      >
                        <TrackingInput
                          placeholder={district}
                          options={{
                            action: 'Enter District',
                            category: 'Contact Us Form | Input',
                          }}
                          onChange={onChangeDistrict}
                        >
                          <Select items={districtList} />
                        </TrackingInput>
                      </Form.Item>
                    )}
                  </FormattedMessage>
                )}
              </FormattedMessage>
              <FormattedMessage {...utilsMessages.ward}>
                {(ward) => (
                  <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: ward }}>
                    {(fieldRequired) => (
                      <Form.Item
                        name={['redInvoiceInfo', 'location', 'ward']}
                        colon={false}
                        required={false}
                        label={ward}
                        rules={[{ required: true, message: fieldRequired }]}
                      >
                        <TrackingInput
                          placeholder={ward}
                          options={{
                            action: 'Enter Ward',
                            category: 'Contact Us Form | Input',
                          }}
                        >
                          <Select items={wardList} />
                        </TrackingInput>
                      </Form.Item>
                    )}
                  </FormattedMessage>
                )}
              </FormattedMessage>
            </AddressInfoContainer>
          </>
        ) : null}
      </CustomPanel>
    </Collapse>
  );
};

export default VATInvoiceFormSection;
