import React, { useState, FC, useEffect, useRef, useMemo } from 'react';

import { CheckOutlined, LockOutlined, MailOutlined, PhoneOutlined, ShopOutlined } from '@ant-design/icons';

import { Input as AInput, Form } from 'antd';

import { FormattedMessage } from 'react-intl';
import Input from 'components/TrackingComponents/Input';
import { Link } from 'react-router-dom';
import PageHeader from '../PageHeader/PageHeader';
import TrackingButton from 'components/TrackingComponents/Button';
import media from 'utils/mediaStyle';
import messages from 'containers/SignupPage/messages';
import styled from 'styled-components';
import topBg from './top-bg.png';
import translations from 'translations';
import utilsMessages from 'utils/messages';
import { ContactUsForm } from 'containers/ContactUsPage/types';
import enLocations from 'translations/en/locations';
import { get, keys } from 'lodash';
import { toCityId, toDistrictId, toWardId } from 'components/LocationFormSection/messages';
import * as Sentry from '@sentry/browser';
import VATInvoiceFormSection from './VATInvoiceFormSection';
import { SelectItem } from 'components/Select/Select';
import { checkEmailRegex } from 'utils/utilities';

const AntdInput = styled(AInput)<any>``;
const AntdPassword = styled(AInput.Password)<any>``;

const Container = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 8pt;
  flex: 1;
  max-width: 720px;
  background-image: url(${topBg});
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 8pt;
  ${media.md`
    padding: 30pt 90pt;
  `};
  p {
    line-height: normal;
    margin: 8px 0;
  }
`;
const PasswordValidationContainer = styled.div`
  margin-top: 4pt;
  i {
    margin-right: 6pt;
  }
`;

export type CustomerInfo = ContactUsForm;

interface SignupFormProps {
  onSubmit: (data: any) => void;
  onCheckEmail: (data: any) => void;
  isCheckingEmail: boolean;
  validationEmailMessage: string;
  customerInfo: CustomerInfo;
}
const SignupForm: FC<SignupFormProps> = (props) => {
  const [form] = Form.useForm();
  const { isCheckingEmail, validationEmailMessage, onCheckEmail, customerInfo, onSubmit } = props;
  const [checkValidPassword, setCheckValidPassword] = useState({
    validSpace: false,
    validNumber: false,
    validLength: false,
    showPopover: false,
  });
  const [enabledVATInvoiceKey, setEnabledVATInvoiceKey] = useState(true);
  const emailValidationCallback = useRef<any>(null);

  const checkEmail = () => () => {
    const email = form.getFieldValue('email');
    return Promise.resolve().then(() => {
      if (!checkEmailRegex.test(String(email).toLowerCase())) {
        return;
      }
      return new Promise((resolve, reject) => {
        emailValidationCallback.current = {
          resolve: resolve,
          reject: reject,
        };
        onCheckEmail(email);
      });
    });
  };

  useEffect(() => {
    if (!isCheckingEmail && emailValidationCallback.current) {
      const { resolve, reject } = emailValidationCallback.current;
      if (validationEmailMessage) {
        reject(validationEmailMessage);
      }
      emailValidationCallback.current = null;
      resolve();
    }
  }, [isCheckingEmail, validationEmailMessage]);

  const checkPassword = (value: any) => {
    const password = value;
    let error = false;
    let validSpace = true;
    let validNumber = true;
    let validLength = true;
    if (!password) {
      setCheckValidPassword({
        validSpace: false,
        validNumber: false,
        validLength: false,
        showPopover: false,
      });
      return Promise.reject();
    }
    if (password.indexOf(' ') > -1) {
      error = true;
      validSpace = false;
    }
    if (!password.match(/\d/)) {
      error = true;
      validNumber = false;
    }
    if (password.length < 8) {
      error = true;
      validLength = false;
    }
    if (error) {
      setCheckValidPassword({
        validSpace: validSpace,
        validNumber: validNumber,
        validLength: validLength,
        showPopover: true,
      });
      return Promise.reject();
    }
    setCheckValidPassword({
      validSpace: validSpace,
      validNumber: validNumber,
      validLength: validLength,
      showPopover: false,
    });
    return Promise.resolve();
  };

  const checkConfirmPassword = async () => {
    const password = form.getFieldValue('password');
    const confirmPassword = form.getFieldValue('confirmPassword');
    if (confirmPassword && password !== confirmPassword) {
      return Promise.reject(translations(messages.confirmPasswordNotCorrect));
    }
    return Promise.resolve();
  };

  const getDistrictsByCity = (city: string): SelectItem[] => {
    return keys(get(enLocations, `${city}.districts`, {})).map((district) => {
      return {
        value: district.trim(),
        label: translations(toDistrictId(city, district)),
      };
    });
  };

  const getWardsByCityAndDistrict = (city: string, district: string): SelectItem[] => {
    return keys(get(enLocations, `${city}.districts.${district}.wards`, {})).map((ward) => {
      return {
        value: ward.trim(),
        label: translations(toWardId(city, district, ward)),
      };
    });
  };

  const resetDistrictAndWard = () =>
    form?.setFieldsValue({
      redInvoiceInfo: {
        location: {
          district: undefined,
          ward: undefined,
        },
      },
    });

  const resetWard = () => form.setFieldsValue({ redInvoiceInfo: { location: { ward: undefined } } });

  const cities: SelectItem[] = useMemo(
    () =>
      keys(enLocations).map((city) => ({
        value: city.trim(),
        label: translations(toCityId(city)),
      })),
    [],
  );

  return (
    <Container>
      <PageHeader size="large">
        <FormattedMessage {...messages.header} />
      </PageHeader>
      <Form
        form={form}
        onFinish={(values) => onSubmit(values)}
        onFinishFailed={(err) => Sentry.captureException(err, { level: Sentry.Severity.Warning })}
        className="login-form"
        layout="vertical"
        size="large"
      >
        <FormattedMessage {...utilsMessages.businessName}>
          {(companyName: string) => (
            <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: companyName }}>
              {(fieldRequired: string) => (
                <Form.Item
                  name="companyName"
                  colon={false}
                  required={false}
                  label={companyName}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: fieldRequired,
                    },
                  ]}
                >
                  <Input
                    InputComponent={AntdInput}
                    prefix={<ShopOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder={companyName}
                    trackingCategory="Signup Form | Input"
                    trackingAction="Enter Company Name"
                  />
                </Form.Item>
              )}
            </FormattedMessage>
          )}
        </FormattedMessage>

        <FormattedMessage {...utilsMessages.phone}>
          {(phone: string) => (
            <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: phone }}>
              {(fieldRequired) => (
                <Form.Item
                  name="telephone"
                  initialValue={customerInfo.phoneNumber}
                  colon={false}
                  required={false}
                  label={phone}
                  hasFeedback
                  rules={[{ required: true, message: fieldRequired }]}
                >
                  <Input
                    InputComponent={AntdInput}
                    prefix={<PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder={phone}
                    autoComplete="phone"
                    trackingCategory="Signup Form | Input"
                    trackingAction="Enter Phone"
                  />
                </Form.Item>
              )}
            </FormattedMessage>
          )}
        </FormattedMessage>

        <FormattedMessage {...utilsMessages.fieldEmail}>
          {(fieldEmail) => (
            <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: 'Email' }}>
              {(fieldRequired) => (
                <Form.Item
                  name="email"
                  initialValue={customerInfo.email}
                  colon={false}
                  required={false}
                  label="Email"
                  hasFeedback
                  rules={[
                    {
                      type: 'email',
                      message: fieldEmail,
                    },
                    { required: true, message: fieldRequired },
                    {
                      validator: checkEmail(),
                    },
                  ]}
                  normalize={(value: string) => value && value.replace(/\s/g, '')}
                >
                  <Input
                    InputComponent={AntdInput}
                    prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Email"
                    autoComplete="username"
                    trackingCategory="Signup Form | Input"
                    trackingAction="Enter Email"
                  />
                </Form.Item>
              )}
            </FormattedMessage>
          )}
        </FormattedMessage>
        <FormattedMessage {...utilsMessages.password}>
          {(password) => (
            <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: password }}>
              {(fieldRequired) => (
                <>
                  <Form.Item
                    name="password"
                    colon={false}
                    required={false}
                    label={password}
                    rules={[
                      {
                        required: true,
                        message: fieldRequired,
                      },
                      {
                        validator: (_, value) => checkPassword(value),
                      },
                      {
                        validator: (_, value) => checkConfirmPassword(),
                      },
                    ]}
                  >
                    <Input
                      InputComponent={AntdPassword}
                      prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                      type="password"
                      placeholder={password}
                      autoComplete="new-password"
                      trackingCategory="Signup Form | Input"
                      trackingAction="Enter Password"
                    />
                  </Form.Item>
                  <PasswordValidationContainer>
                    <div>
                      <CheckOutlined style={{ color: checkValidPassword.validLength ? '#40A9FF' : '#BFBFBF' }} />
                      <FormattedMessage {...messages.atLeast8Char} />
                    </div>
                    <div>
                      <CheckOutlined style={{ color: checkValidPassword.validNumber ? '#40A9FF' : '#BFBFBF' }} />
                      <FormattedMessage {...messages.atLeast1Num} />
                    </div>
                    <div>
                      <CheckOutlined style={{ color: checkValidPassword.validSpace ? '#40A9FF' : '#BFBFBF' }} />
                      <FormattedMessage {...messages.noSpace} />
                    </div>
                  </PasswordValidationContainer>
                </>
              )}
            </FormattedMessage>
          )}
        </FormattedMessage>
        <FormattedMessage {...utilsMessages.passwordConfirmation}>
          {(passwordConfirmation) => (
            <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: passwordConfirmation }}>
              {(fieldRequired) => (
                <Form.Item
                  name="confirmPassword"
                  colon={false}
                  required={false}
                  label={passwordConfirmation}
                  rules={[
                    {
                      required: true,
                      message: fieldRequired,
                    },
                    {
                      validator: (_, value) => checkConfirmPassword(),
                    },
                  ]}
                >
                  <Input
                    InputComponent={AntdPassword}
                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    autoComplete="off"
                    placeholder={passwordConfirmation}
                    trackingCategory="Signin Form | Input"
                    trackingAction="Confirm Password"
                  />
                </Form.Item>
              )}
            </FormattedMessage>
          )}
        </FormattedMessage>
        <VATInvoiceFormSection
          form={form}
          enabledVATInvoiceKey={enabledVATInvoiceKey}
          onEnableGetVATInvoiceEvent={() => setEnabledVATInvoiceKey(!enabledVATInvoiceKey)}
          resetDistrictAndWard={resetDistrictAndWard}
          getDistrictsByCity={getDistrictsByCity}
          resetWard={resetWard}
          cities={cities}
          getWardsByCityAndDistrict={getWardsByCityAndDistrict}
        />

        <Form.Item style={{ marginTop: '6px' }}>
          <TrackingButton
            block
            type="primary"
            htmlType="submit"
            trackingCategory="Signup Form | Button"
            trackingAction="Submit Signup Form"
          >
            <FormattedMessage {...utilsMessages.signup} />
          </TrackingButton>
        </Form.Item>
        <p>
          <FormattedMessage {...messages.alreadyHasAccount} />{' '}
          <Link to="/signin">
            <FormattedMessage {...messages.signinBang} />
          </Link>
        </p>
      </Form>
    </Container>
  );
};

export default SignupForm;
